<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <nav class="breadcrumb">
                    <ol class="breadcrumb-wrapper">
                        <li class="breadcrumb-item"><a href="javascript:">관리자</a></li>
                        <li class="breadcrumb-item">
                            <a href="javascript:">데이터 및 SW 품질 컨설팅</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="javascript:">데이터 및 SW 품질 컨설팅 현황</a>
                        </li>
                    </ol>
                </nav>
                <h2 class="page-title">데이터 및 SW 품질 컨설팅 현황</h2>
                <div class="page-title-bottom"></div>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-aside -->
            <aside class="page-aside">
                <nav class="snb">
                    <ol class="snb-list">
                        <li class="snb-item" :class="{'is-active': status === ''}" @click="selectStatus('')">
                            <a href="javascript:" class="snb-link"
                                >전체 <span class="sub">{{ getConsultingCount('') }}</span></a
                            >
                        </li>
                        <li
                            v-for="(item, index) in statuses"
                            class="snb-item"
                            :class="{'is-active': item.dcd === status}"
                            :key="index"
                            @click="selectStatus(item.dcd)">
                            <a href="javascript:" class="snb-link"
                                >{{ item.name }} <span class="sub">{{ getConsultingCount(item.dcd) }}</span></a
                            >
                        </li>
                    </ol>
                </nav>
            </aside>
            <!-- page-contents -->
            <div class="page-contents">
                <!-- board-list-container -->
                <div class="board-list-container">
                    <!-- board-list -->
                    <ul class="board-list">
                        <!-- board-item -->
                        <li class="board-item" v-for="(item, index) in items" :key="index">
                            <div class="board">
                                <div class="board-column column-idx">{{ getPageNo(paging, index) }}</div>
                                <div class="board-column column-content">
                                    <p
                                        class="board-title swcc-text-skip swcc-cursor-underline swcc-cursor-pointer"
                                        :class="{'dev-text-cursor': session.manager}"
                                        :title="item.consultingNm"
                                        style="width: 600px"
                                        @click="goView(item.consultingNo, $event)">
                                        {{ item.consultingNm }}
                                    </p>
                                    <div class="board-meta">
                                        <strong class="text">{{ item.consultingTarget }}</strong>
                                        <span class="text">{{ timestampToDateFormat(item.createDt, 'yyyy.MM.dd') }}</span>
                                        <!--                                        <span class="text">{{ item.consultingTarget }}</span>-->
                                        <div class="badge">{{ getDomainCodeNm(item.consultingStDcd) }}</div>
                                    </div>
                                </div>
                                <div class="board-column column-actions">
                                    <button
                                        v-if="consultingStDcds.approve !== item.consultingStDcd && consultingStDcds.complete !== item.consultingStDcd"
                                        class="btn-text"
                                        @click="updateConsulting(item.consultingNo, consultingStDcds.approve, item.consultingMemo, updateCallback)">
                                        <span class="text text-blue">승인하기</span>
                                    </button>
                                    <button
                                        v-if="consultingStDcds.reject !== item.consultingStDcd"
                                        class="btn-text"
                                        @click="updateConsulting(item.consultingNo, consultingStDcds.reject, item.consultingMemo, updateCallback)">
                                        <span class="text text-red">반려하기</span>
                                    </button>
                                    <button
                                        v-if="consultingStDcds.approve === item.consultingStDcd"
                                        class="btn-text"
                                        @click="updateConsulting(item.consultingNo, consultingStDcds.complete, item.consultingMemo, updateCallback)">
                                        <span class="text text-green">완료하기</span>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- pagination -->
                    <CommonPagination :paging="paging" :pageFunc="pageFunc" />
                </div>
                <!-- board-list-container -->
            </div>
            <!-- //page-contents -->
        </div>

        <!--        <div class="page-bottom bottom-fixed">-->
        <!--            <div>-->
        <!--                <div class="bottom-search-container bottom-component">-->
        <!--                    <div class="bottom-search">-->
        <!--                        <div class="search-column column-form">-->
        <!--                            <input v-model.trim="keyword" type="search" :placeholder="`${divisions.search} 검색하세요.`" @keyup.enter="searchKeyword" />-->
        <!--                        </div>-->
        <!--                        <div class="search-column column-button">-->
        <!--                            <button class="btn-icon" @click="searchKeyword"><i class="icon-search"></i></button>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </main>
</template>

<script>
import {computed, reactive, ref, watch} from 'vue';
import CommonPagination from '@/components/common/CommonPagination';
import {useRoute, useRouter} from 'vue-router';
import {getCheckItems, getItems, getPageNo, getTotalCount, lengthCheck, timestampToDateFormat} from '@/assets/js/common.utils';
import {getValueByQuery} from '@/assets/js/route.utils';
import {useStore} from 'vuex';
import ApiService from '@/assets/js/api.service';
import {getDomainCodeNm} from '@/assets/js/modules/common/module';
import {consultingStDcds, updateConsulting} from '@/assets/js/modules/consulting/module';

export default {
    name: 'ConsultingStatus',
    components: {CommonPagination},
    setup: function () {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const session = computed(() => store.state.auth.session);

        const isReady = ref(false);

        const currentQuery = computed(() => route.query);
        const statuses = [
            {name: '신청', dcd: '1005001'},
            {name: '승인', dcd: '1005002'},
            {name: '반려', dcd: '1005003'},
            {name: '완료', dcd: '1005004'},
        ];

        const items = ref([]);
        const counts = ref([]);
        const keyword = ref('');
        const search = ref(getValueByQuery(route.query, 'search', false, ''));
        const status = ref(getValueByQuery(route.query, 'status', false, ''));

        const paging = reactive({
            pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
            pageSize: 10,
            totalCount: 0,
        });

        const goView = (consultingNo, e) => {
            if (e.ctrl) {
                window.open(`/hub/consulting/${consultingNo}/view`);
            } else {
                router.push({name: 'ConsultingView', params: {consultingNo}});
            }
        };

        const searchKeyword = () => {
            router.push({query: {...currentQuery.value, pageNo: 1, search: keyword.value}});
        };

        const selectStatus = dcd => {
            if (dcd !== status.value) {
                keyword.value = '';
                router.push({query: {status: dcd}});
            }
        };

        const pageFunc = pageNo => {
            router.push({query: {...currentQuery.value, pageNo: pageNo}});
        };

        const getConsultingCount = consultingStDcd => {
            if (consultingStDcd) {
                const target = counts.value.find(x => x.consultingStDcd === consultingStDcd);
                return target ? target.consultingCount : 0;
            }

            if (counts.value.length > 0) {
                return counts.value.map(x => x.consultingCount).reduce((a, b) => a + b, 0);
            }
            return 0;
        };

        const getConsultingList = () => {
            const params = {consultingStDcd: status.value, pageNo: paging.pageNo, pageSize: paging.pageSize};

            ApiService.query('/v1/consultings', params).then(res => {
                if (lengthCheck(res)) {
                    items.value = getItems(res);
                    paging.totalCount = getTotalCount(res);
                }
                isReady.value = true;
            });

            ApiService.query('/v1/consultings/counts', {}).then(res => {
                counts.value = getCheckItems(res);
            });
        };

        const initConsultingList = () => {
            getConsultingList();
            router.push({query: {...currentQuery.value, pageNo: 1}});
        };

        const updateCallback = () => {
            getConsultingList();
        };

        // 현재 사용하지 않을 예정

        watch(
            () => route.query,
            () => {
                if (route.name && route.name.includes('ConsultingStatus')) {
                    isReady.value = false;
                    items.value = [];
                    paging.totalCount = 0;

                    paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
                    status.value = getValueByQuery(route.query, 'status', false, '');
                    search.value = getValueByQuery(route.query, 'search', false, '');
                    getConsultingList();
                    // 실행 api 실행 됨
                    window.scrollTo(0, 0);
                }
            },
        );

        if (!session.value.manager) {
            router.push({name: 'Main'});
        } else {
            getConsultingList();
        }

        return {
            session,
            keyword,
            isReady,
            statuses,
            status,
            items,
            paging,
            goView,
            searchKeyword,
            getPageNo,
            pageFunc,
            getConsultingCount,
            selectStatus,
            updateCallback,
            initConsultingList,
            timestampToDateFormat,
            getDomainCodeNm,
            updateConsulting,
            consultingStDcds,
        };
    },
};
</script>
