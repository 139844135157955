import {hideLoading, showLoading} from '@/assets/js/common.alert';
import ApiService from '@/assets/js/api.service';
import {isSuccess} from '@/assets/js/common.utils';

export const consultingStDcds = {
    apply: '1005001',
    approve: '1005002',
    reject: '1005003',
    complete: '1005004',
};

export const getConsultingStAction = consultingStDcd => {
    if (consultingStDcds.approve === consultingStDcd) {
        return '승인';
    } else if (consultingStDcds.reject === consultingStDcd) {
        return '반려';
    } else if (consultingStDcds.complete === consultingStDcd) {
        return '완료';
    }
    return '-';
};

export const updateConsulting = (consultingNo, consultingStDcd, consultingMemo, callback) => {
    const action = getConsultingStAction(consultingStDcd);

    if (confirm(`${action}하시겠습니까?`)) {
        showLoading();
        ApiService.update(`/v1/consultings`, consultingNo, {consultingStDcd, consultingMemo})
            .then(res => {
                if (isSuccess(res)) {
                    alert(`${action}되었습니다.`);
                    if (callback instanceof Function) callback(consultingStDcd);
                } else {
                    alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                }
                hideLoading();
            })
            .catch(e => {
                console.error(e);
                alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                hideLoading();
            });
    }
};
