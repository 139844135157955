import store from '@/store';
import {MUT_SET_LOADING} from '@/store/modules/common';

export const showLoading = title => {
    store.commit(`common/${MUT_SET_LOADING}`, {show: true, title});
};

export const hideLoading = () => {
    store.commit(`common/${MUT_SET_LOADING}`, {show: false});
};
