import router from '@/router';

const getDefaultValue = (value, defaultValue) => {
    return defaultValue !== undefined ? defaultValue : value;
};

export const getValueByQuery = (query, key, isNumber, defaultValue) => {
    if (query && query[key]) {
        try {
            return isNumber ? parseInt(query[key]) : query[key];
        } catch (e) {
            return isNumber ? getDefaultValue(0, defaultValue) : getDefaultValue('', defaultValue);
        }
    }
    return isNumber ? getDefaultValue(0, defaultValue) : getDefaultValue('', defaultValue);
};

export const setFilterByQuery = (filter, query, nums) => {
    Object.keys(filter).forEach(k => {
        if (nums) {
            filter[k] = getValueByQuery(query, k, nums[k]);
        } else {
            filter[k] = getValueByQuery(query, k);
        }
    });
};

const getQuery = query => {
    if (query) {
        const result = {};
        Object.keys(query).forEach(k => {
            if (query[k]) result[k] = query[k];
        });
        return result;
    }
    return {};
};

export const pushQuery = query => {
    router.push({query: getQuery(query)}).then();
};
