<template>
    <nav class="pagination" :class="pageClasses">
        <ol v-if="paging.totalCount > 0 && paging.pageNo > 0" class="page-list">
            <li class="page-item" v-if="startPageList - 1 > 0" :class="{disabled: startPageList - 1 === 0}">
                <a
                    href="javascript:"
                    class="page-link"
                    :class="{disabled: startPageList - 1 === 0}"
                    @click="pageFunc(startPageList - 1, paging.pageSize)"
                    aria-label="Previous">
                    <i class="icon-prev" aria-hidden="true"></i>
                    <span class="sr-only">Previous</span>
                </a>
            </li>
            <li :key="idx" v-for="(num, idx) in numberRange" class="page-item" :class="{'is-active': paging.pageNo === num}">
                <a v-if="paging.pageNo === num" class="page-link" href="javascript:">{{ num }}</a>
                <a v-else class="page-link" href="javascript:" @click="pageFunc(num, paging.pageSize)">{{ num }}</a>
            </li>
            <li class="page-item" v-if="endPageList < totalPage" :class="{disabled: endPageList >= totalPage}">
                <a
                    href="javascript:"
                    class="page-link"
                    @click="pageFunc(endPageList + 1, paging.pageSize)"
                    :class="{disabled: endPageList >= totalPage}"
                    aria-label="Next">
                    <i class="icon-next" aria-hidden="true"></i>
                    <span class="sr-only">Next</span>
                </a>
            </li>
        </ol>
    </nav>
</template>
<script>
import {computed, ref, toRef} from 'vue';

export default {
    name: 'CommonPagination',
    props: {
        paging: {
            type: Object,
            default: () => ({
                pageNo: 1,
                pageSize: 10,
                totalCount: 0,
            }),
        },
        pageListSize: {
            type: Number,
            default: 10,
        },
        pageFunc: Function,
        usePageSize: Boolean,
        pageClasses: Array,
    },
    setup(props) {
        const pageSize = ref(toRef(props, 'paging').value.pageSize);

        const totalPage = computed(() => Math.ceil(props.paging.totalCount / props.paging.pageSize));
        const totalPageList = computed(() => Math.ceil(totalPage.value / props.pageListSize));

        const pageList = computed(() => {
            let pageList = Math.ceil(props.paging.pageNo / props.pageListSize);
            if (pageList < 1) pageList = 1;
            if (pageList > totalPageList.value) pageList = totalPageList.value;
            return pageList;
        });

        const startPageList = computed(() => {
            let startPageList = (pageList.value - 1) * props.pageListSize + 1;
            if (startPageList < 1) startPageList = 1;
            return startPageList;
        });

        const endPageList = computed(() => {
            let endPageList = startPageList.value + props.pageListSize - 1;
            if (endPageList > totalPage.value) endPageList = totalPage.value;
            if (endPageList < 1) endPageList = 1;
            return endPageList;
        });

        const numberRange = computed(() => {
            return [...Array(endPageList.value - startPageList.value + 1).keys()].map(x => x + startPageList.value);
        });

        return {
            pageSize,
            totalPage,
            startPageList,
            endPageList,
            numberRange,
        };
    },
};
</script>
